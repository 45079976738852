<template>
  <v-card class="py-5">
    <v-card-title class="justify-center text-h4 pb-10 primary--text"
      >{{ isUpdate ? 'แก้ไข' : 'สร้าง' }}ข้อมูลสินค้าทั่วไป</v-card-title
    >
    <v-card-text class="popup-background">
      <v-row justify="center">
        <v-col cols="4">
          <!-- <v-text-field
            name="product_code"
            label="รหัสสินค้า"
            outlined
            dense
            background-color="white"
            v-model="form.product_code"/> -->
          <v-text-field
            class="mt-2"
            name="name"
            label="ชื่อสินค้า"
            outlined
            dense
            background-color="white"
            v-model="form.name"
            :error-messages="$v.form.name.$error ? $error_text : ''"
          />
          <v-textarea
            name="detail"
            label="รายละเอียดสินค้า"
            outlined
            dense
            background-color="white"
            v-model="form.detail"
          />
          <v-text-field
            name="price"
            label="ราคา"
            outlined
            dense
            background-color="white"
            v-model.number="form.price"
            :error-messages="$v.form.price.$error ? $error_text : ''"
          />
          <v-select
            name="vat"
            label="ภาษีมูลค่าเพิ่ม"
            outlined
            dense
            background-color="white"
            :items="list.vat_dict"
            v-model="form.vat"
            item-value="value"
            item-text="name"
          ></v-select>
          <v-textarea
            value="ต้องเปลี่ยนราคาสินค้า เป็นราคาก่อน Vat ด้วย"
            v-show="form.vat == 1"
            disabled
            rows="1"
          >
          </v-textarea>
        </v-col>
        <v-col cols="4">
          <v-text-field
            class="mt-2"
            name=""
            label="หมวดหมู่หลัก"
            outlined
            dense
            background-color="white"
            v-model="main_category.name"
            disabled
          />
          <v-text-field
            name=""
            label="หมวดหมู่ย่อย"
            outlined
            dense
            background-color="white"
            v-model="sub_category.name"
            disabled
          />
          <!-- <v-textarea
            name="note"
            label="หมายเหตุ"
            outlined
            dense
            background-color="white"
            v-model="form.note"
          /> -->

          <!-- <div>
            แจ้งเตือนเมื่อเลือกสินค้านี้
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" small v-bind="attrs" v-on="on"
                  >mdi-information</v-icon
                >
              </template>
              <v-sheet class="pa-3">info</v-sheet>
            </v-menu>
          </div>

          <v-radio-group row v-model="form.message_alert">
            <v-radio name="" label="ไม่แจ้งเตือน" :value="false"></v-radio>
           <v-spacer/> 
            <v-radio name="" label="แจ้งเตือน" :value="true"></v-radio>
          </v-radio-group> -->
          <!-- <v-textarea
            name=""
            label="ข้อความแจ้งเตือน"
            outlined
            dense
            background-color="white"
            v-model="form.message_on_select"
          /> -->
        </v-col>
        <!-- <v-col cols="4" offset="1">
          <div>
            คู่มือสินค้า
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" small v-bind="attrs" v-on="on"
                  >mdi-information</v-icon
                >
              </template>
              <v-sheet class="pa-3">info</v-sheet>
            </v-menu>
          </div>

          <v-radio-group row v-model="form.manual">
            <v-radio name="" label="เปิด" :value="true"></v-radio>
            <v-radio name="" label="ปิด" :value="false"></v-radio>
          </v-radio-group>
          <v-textarea
            name=""
            label="เอกสารที่ต้องใช้"
            outlined
            dense
            background-color="white"
            v-model="form.require_document"
          />
          <v-text-field
            name=""
            label="ระยะเวลาในการทำ"
            outlined
            dense
            background-color="white"
            v-model="form.process_time"
          />
          <v-textarea
            name=""
            label="ขั้นตอนการทำงาน"
            outlined
            dense
            background-color="white"
            v-model="form.manual_process"
          />
          <v-textarea
            name=""
            label="หมายเหตุคู่มือ"
            outlined
            dense
            background-color="white"
            v-model="form.manual_note"
          />
        </v-col> -->
      </v-row>
    </v-card-text>
    <v-card-actions class="py-5">
      <v-spacer />
      <v-btn outlined class="px-5 mr-10" color="grey" @click="close()"
        >ปิด</v-btn
      >
      <v-btn
        class="px-10 mr-15"
        color="primary"
        @click="save()"
        :loading="loading"
        :disabled="loading"
        >บันทึกข้อมูล</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { required, decimal } from 'vuelidate/lib/validators';

export default {
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    main_category: {
      type: Object,
      default: null,
    },
    sub_category: {
      type: Object,
      default: null,
    },
    dialogUpdate: {
      type: [Boolean],
    },
  },
  validations: {
    form: {
      name: { required },
      price: { required, decimal },
    },
  },
  watch: {
    dialogUpdate(value) {
      if (value) {
        this.getData(this.id);
      }
    },
    // id: function (newVal, oldVal) {
    //   this.getData(newVal);
    // },
  },
  mounted() {
    this.getData(this.id);
  },
  data() {
    return {
      loading: false,
      form: {
        product_code: '',
        name: '',
        detail: '',
        price: 0,
        vat: 0,
        category_id: 0,
        sub_category_id: '',
        note: '',
        message_alert: false,
        message_on_select: '',
        manual: false,
        require_document: '',
        process_time: '',
        manual_process: '',
        manual_note: '',
      },
      list: {
        vat_dict: [
          { value: 0, name: 'ไม่มี Vat' },
          { value: 1, name: 'มี Vat' },
        ],
        main_category: [],
        sub_category: [],
      },
    };
  },
  methods: {
    close() {
      this.resetForm();
      this.$emit('onClose');
    },
    async getData(id) {
      if (id) {
        let body = {
          token: this.$jwt.sign({ product_id: id }, this.$privateKey, {
            noTimestamp: true,
          }),
        };
        await this.$axios
          .post(`${this.$baseUrl}/product/get_by_id`, body)
          .then(async (res) => {
            this.form = { ...res.result };
          })
          .catch((err) => {
            console.log('err', err);
          });
      } else {
        this.resetForm();
      }
      this.loading = false;
    },
    resetForm() {
      this.form = {
        product_code: '',
        name: '',
        detail: '',
        price: 0,
        vat: 0,
        category_id: 0,
        sub_category_id: '',
        note: '',
        message_alert: false,
        message_on_select: '',
        manual: false,
        require_document: '',
        process_time: '',
        manual_process: '',
        manual_note: '',
      };
    },
    save() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;

      this.form.category_id = this.main_category._id;
      this.form.sub_category_id = this.sub_category._id;

      this.loading = true;
      this.$alertConfirm({ title: `ยืนยันบันทึกข้อมูล ?`, text: `` }).then(
        async (result) => {
          if (result.isConfirmed) {
            let subPath = 'add_product';
            if (this.isUpdate) {
              subPath = 'edit_product';
              this.form = { product_id: this.form._id, ...this.form };
              delete this.form._id;
            }
            let body = {
              token: this.$jwt.sign(this.form, this.$privateKey, {
                noTimestamp: true,
              }),
            };

            await this.$axios
              .post(`${this.$baseUrl}/product/${subPath}`, body)
              .then((res) => {
                this.$alertSuccess({ title: res.status });
                this.$v.form.$reset();
                this.$emit('success');
                this.resetForm();
                this.$emit('onClose');
              })
              .catch((err) => {
                console.log('err', err);
                this.$alertServerError({ title: err.error_message });
              });
          }
          this.loading = false;
        }
      );
    },
  },
  computed: {
    isUpdate() {
      return this.id;
    },
  },
};
</script>
